import React from "react";
import ReactDOM from "react-dom/client";
import "semantic-ui-css/semantic.min.css";
// import StateContext from "./salesRecipt/context/stateContext";
import StateContext from "./Pages/SaleComponent/salesRecipt/context/stateContext";
import ContextSales from "./Pages/SaleComponent/salesRecord/context/ContextSales";

import ReturnContext from "./Pages/returnComponent/context/ContextReturn";
// import TransferStateContect from "./Transfer Recipt/context/stateContext";
import TransferStateContect from "./Pages/TransferComponent/Transfer Recipt/context/stateContext";
import TransferReoprtStateContect from "./Pages/TransferComponent/Transfer Record/context/ContextSales";
import PurchaseStateContext from "./Pages/PurchaseComponent/purchaseRecipt/context/stateContext";
import PurchaseReportStateContext from "./Pages/PurchaseComponent/purchaseRecord/context/ContextSales";

import ExpensePaymentStateContext from "./Pages/ExpenseComponent/CashDeposit/context/paymentStateContext";
import ExpenseStateeContext from "./Pages/ExpenseComponent/Expensee/context/stateContext";
import TableStateContext from "./Components/tableComponent/tableContext"
// import { HistoryProvider } from "./HistoryContext";
import { HistoryProvider } from "./HistoryContext";
// import "./index.css";
import './SaasStyling/app.scss'
// import { store } from "./app/store";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ContextSales>
        <StateContext>
          <TransferStateContect>
            <TransferReoprtStateContect>
              <PurchaseStateContext>
                <PurchaseReportStateContext>
                  <ExpenseStateeContext>
                  <ExpensePaymentStateContext>
                    <TableStateContext>
                      <HistoryProvider>
                        <ReturnContext >
                          <App />
                        </ReturnContext>
                      </HistoryProvider>
                    </TableStateContext>
                    </ExpensePaymentStateContext>
                  </ExpenseStateeContext>
                </PurchaseReportStateContext>
              </PurchaseStateContext>
            </TransferReoprtStateContect>
          </TransferStateContect>
        </StateContext>
      </ContextSales>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
